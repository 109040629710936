<!-- eslint-disable vue/no-v-html -->
<template>
  <CCol>
    <CRow>
      <h4>Price Changes</h4>
    </CRow>
    <CRow>
      <RDataTable
        :items="data"
        :fields="columns"
        :table-props="{ sorter: false }"
        class="price-changes-table"
      >
        <template #property="{ item }">
          <td>
            <CLink :href="getPropertyLink(item)" target="_blank">
              {{ item.property.name }}
            </CLink>
          </td>
        </template>
        <template #company="{ item }">
          <td>
            <CLink :href="getCompanyLink(item)" target="_blank">
              {{ item.company.human_name }}
            </CLink>
          </td>
        </template>
        <template #timestamp="{ item }">
          <td>
            {{ format(new Date(item.timestamp), 'MMM d, yyyy - h:mm a') }}
          </td>
        </template>
        <template #rate="{ item }">
          <td v-html="getAmount(item, 'rate')" />
        </template>
        <template #discount="{ item }">
          <td v-html="getAmount(item, 'discount')" />
        </template>
        <template #total="{ item }">
          <td v-html="getTotal(item)" />
        </template>
        <template #setup_fee="{ item }">
          <td v-html="getAmount(item, 'setup_fee')" />
        </template>
      </RDataTable>
    </CRow>
  </CCol>
</template>

<script>
import { format } from 'date-fns'

import { RDataTable } from '@/components/tables'

const adminUrl = process.env.VUE_APP_ADMIN_URL

export default {
  name: 'PriceChangesTable',
  components: { RDataTable },
  props: {
    data: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      columns: [
        {
          key: 'property',
          label: 'Property'
        },
        {
          key: 'company',
          label: 'Company'
        },
        {
          key: 'timestamp',
          label: 'Time Changed'
        },
        {
          key: 'modified_by',
          label: 'Changed By'
        },
        {
          key: 'subscription',
          label: 'Subscription'
        },
        {
          key: 'rate',
          label: 'Rate'
        },
        {
          key: 'discount',
          label: 'Discount'
        },
        {
          key: 'total',
          label: 'Total'
        },
        {
          key: 'setup_fee',
          label: 'Set Up Fee'
        }
      ]
    }
  },
  created () {
    this.format = format
  },
  methods: {
    /**
     * Generate the admin link for the property edit page
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getPropertyLink (row) {
      return `${adminUrl}companies/${row.company.id}/properties/${row.property.id}`
    },
    /**
     * Generate the admin link for the company summary page
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getCompanyLink (row) {
      return `${adminUrl}companies/${row.company.id}/properties`
    },
    /**
     * Returns the cell amount along with its difference in HTML.
     * If a field is null, it is unknown (we don't have a record for it).
     *
     * @param {Object} row - table row item
     * @param {String} field - field name (e.g. rate, discount, setup_fee)
     * @returns {String} - value in html
     */
    getAmount (row, field) {
      if (!row.current_prices[field]) {
        return 'Unknown'
      }
      const currentValue = row.current_prices[field]
      const previousValue = row.previous_prices[field]
      const difference = previousValue ? (currentValue - previousValue).toFixed(2) : 'Unknown'
      return `${currentValue}${this.getDifferenceElement(difference)}`
    },
    /**
     * Returns the total amount along with its difference in HTML.
     * If a field is null, it is unknown (we don't have a record for it).
     *
     * @param {Object} row - table row item
     * @returns {String} - value in html
     */
    getTotal (row) {
      if (!row.current_prices.rate || !row.current_prices.discount) {
        return 'Unknown'
      }
      const currentTotal = row.current_prices.rate - row.current_prices.discount
      const previousTotal = row.previous_prices.rate - row.previous_prices.discount
      const difference = row.previous_prices.discount ? (currentTotal - previousTotal).toFixed(2) : 'Unknown'
      return `${currentTotal.toFixed(2)}${this.getDifferenceElement(difference)}`
    },
    /**
     * Returns the difference from the current & previous prices in HTML
     *
     * @param {String|Number} difference
     * @returns {String}
     */
    getDifferenceElement (difference) {
      if (difference === 'Unknown') {
        return ` <span>(&#177;${difference})</span>`
      } else if (difference > 0) {
        return ` <span class="green-text">(+${difference})</span>`
      } else if (difference < 0) {
        return ` <span class="red-text">(${difference})</span>`
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.price-changes-table {
  width: 100%;
  ::v-deep .green-text {
    color: $success;
  }
  ::v-deep .red-text {
    color: $danger;
  }
}
</style>
