<template>
  <CCol>
    <CRow>
      <h4>Product Upgrades & Downgrades</h4>
    </CRow>
    <CRow>
      <RDataTable
        :items="data"
        :fields="columns"
        :table-props="{ sorter: false }"
        class="product-changes-table"
      >
        <template #property="{ item }">
          <td>
            <CLink :href="getPropertyLink(item)" target="_blank">
              {{ item.property.name }}
            </CLink>
          </td>
        </template>
        <template #company="{ item }">
          <td>
            <CLink :href="getCompanyLink(item)" target="_blank">
              {{ item.company.human_name }}
            </CLink>
          </td>
        </template>
        <template #timestamp="{ item }">
          <td>
            {{ format(new Date(item.timestamp), 'MMM d, yyyy - h:mm a') }}
          </td>
        </template>
        <template #previous_product="{ item }">
          <td>
            <div>{{ item.previous_product.product }}</div>
            <div class="caption">
              Rate: {{ item.previous_product.rate }},
              Discount: {{ item.previous_product.discount || '0.00' }},
              Total: {{ (item.previous_product.rate - item.previous_product.discount).toFixed(2) }},
              Set Up Fee: {{ item.previous_product.setup_fee }}
            </div>
          </td>
        </template>
        <template #new_product="{ item }">
          <td>
            <div>{{ item.new_product.product }}</div>
            <div class="caption">
              Rate: {{ item.new_product.rate }},
              Discount: {{ item.new_product.discount || '0.00' }},
              Total: {{ (item.new_product.rate - item.new_product.discount).toFixed(2) }},
              Set Up Fee: {{ item.new_product.setup_fee }}
            </div>
          </td>
        </template>
      </RDataTable>
    </CRow>
  </CCol>
</template>

<script>
import { format } from 'date-fns'

import { RDataTable } from '@/components/tables'

const adminUrl = process.env.VUE_APP_ADMIN_URL

export default {
  name: 'ProductChangesTable',
  components: { RDataTable },
  props: {
    data: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      columns: [
        {
          key: 'property',
          label: 'Property'
        },
        {
          key: 'company',
          label: 'Company'
        },
        {
          key: 'timestamp',
          label: 'Time Changed'
        },
        {
          key: 'modified_by',
          label: 'Changed By'
        },
        {
          key: 'previous_product',
          label: 'Previous Product'
        },
        {
          key: 'new_product',
          label: 'New Product'
        }
      ]
    }
  },
  created () {
    this.format = format
  },
  methods: {
    /**
     * Generate the admin link for the property edit page
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getPropertyLink (row) {
      return `${adminUrl}companies/${row.company.id}/properties/${row.property.id}`
    },
    /**
     * Generate the admin link for the company summary page
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getCompanyLink (row) {
      return `${adminUrl}companies/${row.company.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.product-changes-table {
  width: 100%;

  .caption {
    color: $secondary-400;
    font-size: 0.75rem;
  }
}
</style>
