var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCol',[_c('CRow',[_c('h4',[_vm._v("Product Upgrades & Downgrades")])]),_c('CRow',[_c('RDataTable',{staticClass:"product-changes-table",attrs:{"items":_vm.data,"fields":_vm.columns,"table-props":{ sorter: false }},scopedSlots:_vm._u([{key:"property",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"href":_vm.getPropertyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.property.name)+" ")])],1)]}},{key:"company",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"href":_vm.getCompanyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.company.human_name)+" ")])],1)]}},{key:"timestamp",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.format(new Date(item.timestamp), 'MMM d, yyyy - h:mm a'))+" ")])]}},{key:"previous_product",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(item.previous_product.product))]),_c('div',{staticClass:"caption"},[_vm._v(" Rate: "+_vm._s(item.previous_product.rate)+", Discount: "+_vm._s(item.previous_product.discount || '0.00')+", Total: "+_vm._s((item.previous_product.rate - item.previous_product.discount).toFixed(2))+", Set Up Fee: "+_vm._s(item.previous_product.setup_fee)+" ")])])]}},{key:"new_product",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(item.new_product.product))]),_c('div',{staticClass:"caption"},[_vm._v(" Rate: "+_vm._s(item.new_product.rate)+", Discount: "+_vm._s(item.new_product.discount || '0.00')+", Total: "+_vm._s((item.new_product.rate - item.new_product.discount).toFixed(2))+", Set Up Fee: "+_vm._s(item.new_product.setup_fee)+" ")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }