<template>
  <div>
    <CRow align-horizontal="between" align-vertical="center">
      <CCol sm="auto" class="d-flex align-items-center">
        <h2>
          Subscription Changelog
        </h2>
      </CCol>
      <CCol sm="auto" class="d-flex align-items-center">
        <div class="pr-2 daterange-select">
          Showing reports for
        </div>
        <RDateRangePicker
          v-model="dateRange"
          :max-date="new Date()"
        />
      </CCol>
    </CRow>
    <CRow class="mb-4">
      <CCol>
        <h6 class="subtitle">
          The following report shows price changes, upgrades/downgrades, and reactivations from subscriptions.
        </h6>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RSpinnerOverlay
          :loading="loading"
          :opacity="0.8"
          :z-index="1028"
          :is-full-page="true"
        >
          <PriceChangesTable
            :data="data.price_changes"
            class="mb-4"
          />
          <ProductChangesTable
            :data="data.subscription_changes"
            class="mb-4"
          />
          <ReactivationsTable
            :data="data.reactivations"
          />
        </RSpinnerOverlay>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { endOfDay, formatISO, startOfDay, subMonths } from 'date-fns'

import { RDateRangePicker } from '@/components/dates'
import { RSpinnerOverlay } from '@/components/spinners'
import { RooofAPI } from '@/services/api/resources'

import PriceChangesTable from './_components/PriceChangesTable'
import ProductChangesTable from './_components/ProductChangesTable'
import ReactivationsTable from './_components/ReactivationsTable'

export default {
  name: 'SubscriptionChangelog',
  components: {
    RDateRangePicker,
    RSpinnerOverlay,
    PriceChangesTable,
    ProductChangesTable,
    ReactivationsTable
  },
  data () {
    return {
      loading: false,
      dateRange: {
        start: subMonths(new Date(), 1),
        end: new Date()
      },
      data: {
        price_changes: [],
        reactivations: [],
        subscription_changes: []
      }
    }
  },
  watch: {
    dateRange () {
      this.fetchSubscriptionChangelog()
    }
  },
  created () {
    this.fetchSubscriptionChangelog()
  },
  methods: {
    /**
     * Fetches the billing changes report from the API.
     */
    async fetchSubscriptionChangelog () {
      try {
        this.loading = true
        const params = new URLSearchParams()
        params.append('timestamp_after', formatISO(startOfDay(this.dateRange.start)))
        params.append('timestamp_before', formatISO(endOfDay(this.dateRange.end)))
        const response = await RooofAPI.billingChanges.list({ params })
        this.data = response.data
      } catch (err) {
        this.$store.dispatch('error/show', err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .input {
  color: $info;
}
.daterange-select {
  color: $info;
  font-size: 1.1rem;
}

.subtitle {
  font-weight: normal;
  color: $secondary-600;
}
</style>
