var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCol',[_c('CRow',[_c('h4',[_vm._v("Reactivations")])]),_c('CRow',[_c('RDataTable',{staticClass:"reactivations-table",attrs:{"items":_vm.data,"fields":_vm.columns,"table-props":{ sorter: false }},scopedSlots:_vm._u([{key:"property",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"href":_vm.getPropertyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.property.name)+" ")])],1)]}},{key:"company",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"href":_vm.getCompanyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.company.human_name)+" ")])],1)]}},{key:"timestamp",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.format(new Date(item.timestamp), 'MMM d, yyyy - h:mm a'))+" ")])]}},{key:"reactivation_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.reactivation_date ? _vm.format(new Date(item.reactivation_date), 'MMM d, yyyy') : 'Unknown')+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }